import {FaInstagram} from "react-icons/all";

export const DETAILS = {
    name: "صنایع برودتی چکاد",
    shortName: "صنایع برودتی چکاد",
    footerName: "صنایع برودتی چکاد",
    description: "",
    shortAbout: "صنایع برودتی چکاد، با بیش از 20 سال سابقه کار در زمینه‌ی صنعت یخچال‌های صنعتی و فروشگاهی، به عنوان ارائه‌دهنده‌ تمامی نیازهای شما در این حوزه فعالیت می‌کنیم. در این قسمت، ما به طور خلاصه به شما اطلاعاتی راجع به خدماتمان ارائه می‌دهیم.\n" +
        "                                            ما یک تیم تخصصی با بیش از 20 سال سابقه کار در زمینه‌ یخچال‌های صنعتی و فروشگاهی هستیم. با داشتن دانش و تجربه‌ی گسترده، ما قادر به ارائه‌ی بهترین و جدیدترین محصولات و خدمات در این صنعت هستیم.",
    veryShortAbout: "",
    link: "https://chekadice.com",
    mobile: ["09124620352", "09032250352" , "09922278440"],
    phone: [],
    email: [],
    postalCode: [],
    address: "کرج، اصفهانی ها، بلوار چمران، جنب سازمان آب، صنایع برودتی چکاد",
    supportDescription: "",
    workingHours: [],
    mapIframeSrc: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d6471.180689723909!2d51.005945!3d35.809989!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDQ4JzM2LjAiTiA1McKwMDAnMjEuNCJF!5e0!3m2!1sen!2s!4v1708241994384!5m2!1sen!2s",
    socialMedia: [
        /*{
            title: 'واتس اپ',
            value: '09380637888',
            name: 'whatsapp',
            link: 'https://api.whatsapp.com/send/?phone=09380637888&text&app_absent=0',
            icon: <i className="lab la-whatsapp"/>,
            isPrimary: true
        },*/
        /*{
            title: 'تلگرام',
            value: '09357195494',
            name: 'telegram',
            link: 'https://t.me/+989357195494',
            icon: <i className="lab la-telegram-plane"/>,
            isPrimary: true
        },*/
        {
            title: 'اینستاگرام',
            value: 'mehrdadhoseini4',
            name: 'instagram',
            link: "https://instagram.com/mehrdadhoseini4",
            icon: <FaInstagram/>,
            isPrimary: true
        },
        /*{
                    title: 'ایتا',
                    value: '09357195494',
                    name: 'eitaa',
                    link: 'https://eitaa.com/09357195494',
                    icon: <img src={eitaaIcon} alt='ایتا'/>,
                    isPrimary: false
                }
        {
            title: 'ایمیل',
            value: 'Volvotak@gmail.com',
            name: 'email',
            link: 'mailto:Volvotak@gmail.com',
            icon: <i className="las la-envelope"></i>,
            isPrimary: true
        },
        {
            title: 'تس',
            value: '09133377432',
            name: 'phone',
            link: 'tel:989133377432',
            icon: <PhoneCall/>,
            isPrimary: true
        },
                /*{
                    title: 'آپارات',
                    value: 'Alo_aghsat',
                    name: 'aparat',
                    link: 'https://www.aparat.com/Alo_aghsat',
                    icon: <SiAparat size={25}/>,
                    isPrimary: true
                },
                {
                    title: 'تماس',
                    value: '02636634893',
                    name: 'phone',
                    link: 'tel:+982636634893',
                    icon: <PhoneCall/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09331074475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09332094475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09330324475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'ایتا',
                    value: '09331074475',
                    name: 'eitaa',
                    link: '',
                    icon: <img src={eitaaIcon} alt='ایتا'/>,
                    isPrimary: false
                },*/
    ],
    services: [],
    brands: [
        /*{
            name: 'Apple',
            image: <img src={appleImage} alt='apple'/>,
        },*/
    ],
    enamad: {
        link: "https://trustseal.enamad.ir/?id=462910&Code=iUMb3Q0RhB5HlDA0PbxPggMksqTzoEtf",
        src: "https://trustseal.enamad.ir/logo.aspx?id=462910&Code=iUMb3Q0RhB5HlDA0PbxPggMksqTzoEtf",
        code:'iUMb3Q0RhB5HlDA0PbxPggMksqTzoEtf'
    },
    "samandehiLink": "",
    "ZarinpalLink": "https://www.zarinpal.com/webservice/TrustCode"
}
